import CompanyImage from './assets/images/turinge_larsson_logotype.png';
import './App.css';

function App() {
    return (<div className="App">
            <div className="top-section">
                <div className="content hero">
                    <div className="company-image">
                        <img alt="Larsson i Turinge Logotype" src={CompanyImage} />
                    </div>
                    <h1 style={{ display: 'none' }}>Larsson i Turinge</h1>
                    <h2>
                        Däck, trädgårdsprodukter och mycket mer.<br /><br />
                    </h2>
                </div>
                <div className="grid-section-3 basic-info-section">
                    <div className="basic-info-column">
                        <div className="basic-info-wrapper">
                            <div className="basic-info-title">
                                <h3 style={{ marginBottom: '-17px' }}>
                                    Öppettider:
                                </h3>
                            </div>
                            <div className="basic-info-content">
                                <p>
                                    <table className="open-hours winter">
                                        <tbody>
                                        <tr>
                                            <td>Mån-Fre:</td>
                                            <td>08.00 - 18.00 (däckskiftning till 17.30)</td>
                                        </tr>
                                        <tr>
                                            <td>Helger:</td>
                                            <td>08.00 - 15.00</td>
                                        </tr>
                                       {/* <tr>
                                            <td>Söndag:</td>
                                            <td>Stängt</td>
                                        </tr>*/}
                                        </tbody>
                                    </table>
                                </p>

                                <div style={{ fontSize: '16px' }}>OBS! Öppettiderna kan variera<br/> på röda dagar och högtider.</div>
                            </div>
                        </div>
                    </div>
                    <div className="basic-info-column">
                        <div className="basic-info-wrapper">
                            <div className="basic-info-title">
                                <h3 style={{ marginBottom: '-17px' }}>
                                    Kontakta oss:
                                </h3>
                            </div>
                            <div className="basic-info-content">
                                <p>
                                    <table className="contact-info">
                                        <tbody>
                                        <tr>
                                            <td>Telefon:</td>
                                            <td>08552 403 40</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </p>
                                <p>
                                    Gamla Strängnäsvägen 342<br />
                                    15591 Nykvarn<br /><br />
                                    <a
                                        href="https://www.facebook.com/profile.php?id=100068434028249"
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                    >
                                        Följ oss på Facebook
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="separator" />

            <div className="grid-section-3 sales-section">
                <div className="sales-tires">
                    <div className="sales-title">
                        Däck
                    </div>
                    <div className="sales-content">
                        <div>
                            <ul>
                                <li>Däckbyte</li>
                                <li>Däckhotell</li>
                                <li>Försäljning</li>
                                <li>Omläggning</li>
                                <li>Balansering</li>
                                <li>Lagning</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sales-gardening">
                    <div className="sales-title">
                        Trädgårdsprodukter
                    </div>
                    <div className="sales-content">
                        <div>
                            <ul>
                                Jord från <a href="https://www.sodraarhultstorv.se/" rel="noreferrer" target="_blank">Södra
                                Århults
                                torv</a>.
                                <br />
                                <b>Kan även levereras.</b>
                                <br />
                                <br />
                                <li>Planteringsjord</li>
                                <li>Barkmull</li>
                                <li>Täckbark</li>
                                <li>Rosjord</li>
                                <li>Kogödsel</li>
                                <li>Rhododendronjord</li>
                                <li>Hönsgödsel</li>
                                <li>Dressjord</li>
                                <li>Pelargonjord</li>
                                <li>Blomjord</li>
                                <li>Urnjord</li>
                                <li>Naturtorv</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sales-misc">
                    <div className="sales-title">
                        Övrigt
                    </div>
                    <div className="sales-content">
                        <div>
                            <ul>
                                <li>Vedförsäljning, <b>även leverans</b></li>
                                <li>Biltillbehör</li>
                                <li>Mindre kiosk</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="separator" />

            <footer>
                <p>Larsson I Turinge AB<br />
                    <a
                        href="https://www.facebook.com/profile.php?id=100068434028249"
                        rel="noreferrer"
                        target="_blank"
                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                    >
                        <b>Följ oss på Facebook</b>
                    </a>
                </p>
                <p>
                    08552 403 40
                </p>
                <p>
                    Gamla Strängnäsvägen 342<br />
                    15591 Nykvarn<br /><br />
                </p>
            </footer>
        </div>
    );
}

export default App;
